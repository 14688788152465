import React from 'react';
import { observer } from 'mobx-react';
import InputMask from 'react-input-mask';
import { DatePicker } from './DatePicker';
import './dateTimePicker.scss';
import t from 'i18n';
import classNames from 'classnames';

@observer
export class DateTimePicker extends React.Component {
	static propTypes = DatePicker.PropTypes;

	static defaultProps = {
		isClearable: true,
		timeIntervals: 60,
	};

	onSelect = (date) => {
		const { setMaxTimeOnSelect, maxTime, minTime, setMinTimeOnSelect, onChange } = this.props;
		if (setMaxTimeOnSelect && maxTime) {
			date = new Date(date.setHours(maxTime.getHours(), maxTime.getMinutes()));
			onChange(date);
		} else if (setMinTimeOnSelect && minTime) {
			date = new Date(date.setHours(minTime.getHours(), minTime.getMinutes()));
			onChange(date);
		}
	};

	render() {
		const { value, onChange, onClose, className, minDate, maxDate, minTime, maxTime, timeIntervals, label, placeholder } = this.props;
		const { onSelect } = this;
		const _className = classNames(className, 'date-time-picker-popper');
		// дивом обязательно нужно обенуть, так как дейтпикер создает еще элемент при открытии и надо чтоб он лежал в контейнере
		return (
			<DatePicker
				label={label}
				popperClassName={_className}
				minTime={minTime}
				maxTime={maxTime}
				onSelect={onSelect}
				placeholderText={placeholder || t('dateTimePickerPlaceholder')}
				minDate={minDate}
				maxDate={maxDate}
				onBlur={onClose}
				onCalendarClose={onClose} // колбеки
				toggleCalendarOnIconClick // открыт при клике в иконку
				value={value}
				onChange={onChange}
				dateFormat='dd.MM.yyyy HH:mm' // формат в котором будет установлена дата
				showTimeSelect // выбор времени из колеса
				timeIntervals={timeIntervals} // интервал при выборе врмени
				customInput={
					<InputMask mask='99.99.9999 99:99' value={value} onChange={onChange}>
						{(inputProps) => <input {...inputProps} type='text' />}
					</InputMask>
				}
			/>
		);
	}
}

