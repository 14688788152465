import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Button } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';
import { TruncateText } from '../text';
import { TextField } from '@consta/uikit/TextField';

/*
const inputs = [
	'Select',
	'RecordSelect',
];
*/

@observer
export default class Editable extends React.Component {
	@observable edit = false;
	@observable changed = false;
	@observable innerValue = null;

	constructor(props) {
		super(props);
		this.innerValue = props.value;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.innerValue = this.props.value;
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}

	onMount = (el) => (this.el = el);

	onMouseDown = (e) => {
		if (this.edit && this.el && !this.el.contains(e.target)) {
			this.submit();
		}
	};

	startEdit = () => {
		if (!this.edit) this.edit = true;
	};

	stopEdit = () => (this.edit = false);

	submit = (e) => {
		if (e) e.stopPropagation();
		this.stopEdit();
		if (!this.changed) return;
		this.changed = false;
		this.props.onChange && this.props.onChange(this.innerValue);
	};

	onChange = (e) => {
		this.innerValue = e.target.value;
		this.changed = true;
	};

	render() {
		const { value, rows, placeholder, className, width } = this.props;

		const style = { width };
		const noValue = this.innerValue !== 0 && !this.innerValue; // falsy value
		const output = !noValue ? this.innerValue : placeholder || '';

		const inputProps = {
			value: this.innerValue || '',
			onChange: this.onChange,
			autoFocus: true,
		};

		let input = rows ? <TextField type='textarea' rows={rows} {...inputProps} /> : <input type='text' {...inputProps} />;

		const _className = classNames('editable', className, {
			empty: noValue,
			textarea: !!rows,
		});

		return (
			<div className={_className} style={style} ref={this.onMount}>
				{this.edit ? (
					<>
						{input}
						<div className='submit'>
							<Button variant='link' onClick={this.submit}>
								{t('ok')}
							</Button>
						</div>
					</>
				) : (
					<TruncateText className='editable-value' onClick={this.startEdit}>
						<span dangerouslySetInnerHTML={{ __html: output.replace(/[\r\n]/g, '<br />') }} />
					</TruncateText>
				)}
			</div>
		);
	}
}

